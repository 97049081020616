const API_BASE_PATH = process.env.API_BASE_PATH || '';
const PROXY_HOST = process.env.PROXY_HOST || '';
const NODE_ENV = process.env.NODE_ENV || 'production';
const REFRESH_INTERVAL = process.env.REFRESH_INTERVAL || '10';
const REACT_APP_WS_URL =
  process.env.REACT_APP_WS_URL ||
  (window.location.hostname
    ? `wss://ws.${window.location.hostname}/`
    : 'wss://ws.concentra.dev.docspera.co/');

const config = {
  app: {
    env: NODE_ENV,
    refreshInterval: parseInt(REFRESH_INTERVAL, 10),
    wsUrl: REACT_APP_WS_URL,
  },
  proxy: {
    host: PROXY_HOST,
  },
  resourceServer: {
    basePath: API_BASE_PATH,
  },
};

export default config;
